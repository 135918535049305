import React from "react";
import "./careers.css";
import { Link } from "react-router-dom";
import GrowthOpportunities from "../../assets/svg/Growth_Opportunities.svg";
import CuttingEdge from "../../assets/svg/Careers_Cutting_Edge.svg";
import InclusiveCulture from "../../assets/svg/Careers_Inclusive_Culture.svg";
import ClientImpact from "../../assets/svg/Careers_Client_Impact.svg";
import WorkLife from "../../assets/svg/Careers_Work_Life.svg";
import ContinuousLearning from "../../assets/svg/Careers_Continuous_Learning.svg";
import CareersSpace from "../../assets/svg/careers-space.svg";
import CareersPic1 from "../../assets/images/creersPic1.png";
import careersPicture3 from "../../assets/images/careersPart5.png";
import LazyMedia from "../../lazyLoad/lazyLoad";

function ContentCareers() {
  return (
    <>
      <div className="CareersContainer">
        {/* Careers PartOne*/}
        <div className="careersPartOneMain">
          {/* here will be for logo  */}

          <div className="careersPartOne">
            <h2 className="careersPartOne_H2">Careers at Phoenix</h2>
            <h4 className="careersPartOne_H4">Become a part of Phoenix</h4>
            <p className="careersPartOne_P">
              Stepping into Our family! We're thrilled that you're interested in joining our team. Here, we believe that our employees are the heart and soul of
              our company. We're dedicated to fostering a culture of growth, innovation, and collaboration. Below, you'll find all the information you need to
              embark on a career journey with Phoenix.
            </p>
          </div>
        </div>
        {/* Careers PartTWO */}
        <div className="careersPartTwo">
          <div className="careersPartTwo_first">
            <h1>What Sets Phoenix Apart?</h1>
          </div>
          <div className="careersPartTwo_second">
            <div className="careersPartTwo_second_Container">
              {/* Careers Part1 */}
              <div className="careersP2_S1">
                <div className="careersP2_S_E1">
                  <LazyMedia type="image" src={GrowthOpportunities} alt="GrowthOpportunities" />
                  <h3>1.Growth Opportunities</h3>
                </div>
                <div className="careersP2_S_E2">
                  <p>
                    At Phoenix, we believe in nurturing talent and promoting from within. Joining us means access to a wealth of growth opportunities and career
                    advancement.
                  </p>
                </div>
              </div>
              {/* Careers P2 */}

              <div className="careersP2_S1">
                <div className="careersP2_S_E1">
                  <LazyMedia type="image" src={CuttingEdge} alt="CuttingEdge" />
                  <h3>2.Cutting-Edge Technology</h3>
                </div>
                <div className="careersP2_S_E2">
                  <p>
                    Be at the forefront of innovation. Phoenix is dedicated to using the latest technologies to solve complex problems and drive digital
                    transformation.
                  </p>
                </div>
              </div>
              {/* Careers P3 */}
              <div className="careersP2_S1">
                <div className="careersP2_S_E1">
                  <LazyMedia type="image" src={InclusiveCulture} alt="InclusiveCulture" />
                  <h3>3.Inclusive Culture</h3>
                </div>
                <div className="careersP2_S_E2">
                  <p>
                    Our diverse and inclusive culture ensures that every voice is heard and every perspective matters. We value and respect our team members for
                    who they are.
                  </p>
                </div>
              </div>
            </div>
            <div className="careersPartTwo_second_Container">
              {/* Careers P4 */}
              <div className="careersP2_S1">
                {" "}
                <div className="careersP2_S_E1">
                  <LazyMedia type="image" src={ClientImpact} alt="ClientImpact" />
                  <h3>4.Client Impact</h3>
                </div>
                <div className="careersP2_S_E2">
                  <p>Join a team that makes a real impact. Phoenix works with top-tier clients across industries, helping them transform and succeed.</p>
                </div>
              </div>
              {/* Careers P5 */}
              <div className="careersP2_S1">
                <div className="careersP2_S_E1">
                  <LazyMedia type="image" src={WorkLife} alt="WorkLife" />

                  <h3>5.Work-Life Balance</h3>
                </div>
                <div className="careersP2_S_E2">
                  <p>
                    We understand that life extends beyond work. Phoenix promotes a healthy work-life balance, offering flexible schedules and remote work
                    options.
                  </p>
                </div>
              </div>
              {/* Careers P6 */}
              <div className="careersP2_S1">
                <div className="careersP2_S_E1">
                  <LazyMedia type="image" src={ContinuousLearning} alt="ContinuousLearning" />
                  <h3>6.Continuous Learning</h3>
                </div>
                <div className="careersP2_S_E2">
                  <p>
                    Phoenix is committed to your professional growth. Access to ongoing training and development programs keeps your skills sharp and relevant.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="CareersSpace">
          <LazyMedia type="image" src={CareersSpace} alt="CareersSpace" className="CareersSpace" />
        </div>
        {/* Careers PartThree */}
        <div className="careersPartThree">
          <div className="careersPartThree_First">
            <h2>Phoenix as a workplace</h2>
            <p>
              At Phoenix, we embrace a dynamic and challenging consulting environment. We hold our team members to high standards, just as our clients expect
              the best from us. Our culture thrives in a flat organizational structure, where collaboration and quick decision-making are the norm .{" "}
              <p>
                We value individuals who bring a balanced approach to their work, excelling both professionally and socially. We understand that you have a life
                beyond work, and we respect the importance of work-life balance.
              </p>
            </p>
          </div>
          <LazyMedia type="image" src={CareersPic1} alt="CareersPic1" className="CareersPic1" />
        </div>
        {/* <div className="CareersSpace">
          <CareersSpace />
        </div> */}
        {/* Careers PartFour */}
        {/* <div className="careersPartFour">
          <div>
            <h1>What our Employees say?</h1>
          </div>
          <div className="careersPartFourContain">
            <div className="careersPartFour_First">
              <div>
                <LazyMedia type="image"
                  src={careersPicture}
                  alt="ahmed"
                  className="CareersPartFourImg"
                />
              </div>
              <div className="careersPartFour_Element">
                <h3>
                  “I've been working at Phoenix Consulting for over a year now,
                  and I can honestly say it's been the best job I've ever had.
                  The people, the culture, the work - it's all top-notch.”
                </h3>
                <p>- Ahmed Raafat</p>
              </div>
            </div>
            <div className="careersPartFour_Second">
              <div>
                <LazyMedia type="image"
                  src={careersPicture2}
                  alt="ahmed"
                  className="CareersPartFourImg"
                />
              </div>
              <div className="careersPartFour_Element">
                <h3>
                  “I would highly recommend Phoenix Consulting to anyone looking
                  for a great place to work. It's a company that truly values
                  its employees and is committed to helping them succeed.”
                </h3>
                <p>- Ahmed Raafat</p>
              </div>
            </div>
          </div>
        </div> */}
        {/* Careers PartFive */}
        <div className="careersPartFive">
          <LazyMedia type="image" src={careersPicture3} alt="team" className="CareersPartFiveImg" />
          <div className="careersPartFive_First">
            <div className="careersPartFive_Head">
              <h1>join our team, join our swarm</h1>
            </div>
            <p className="careersPartFive_Head_P">
              Are you ready to take the next step in your career? Applying to join Phoenix is simple. We've streamlined our application process to make it as
              easy as possible for you to submit your credentials and showcase your talents. Check out our Form and start your journey with us.
            </p>
            <Link to="/Contact-us">
              <button>Join Our Team</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContentCareers;
