import React from "react";
import "./home.css";
import { Link } from "react-router-dom";
import HomeSlideOne from "../../components/hooks/HomePartOneSlider/HomePartOneSlider";
import HomePartThreeSliders from "../../components/hooks/HomePartThreeSliders/HomePartThreeSliders";
import HomeP4AccordionItem from "../../components/hooks/HomePartFourAcc/HomePartFourAcc";
import HomePartFiveInfo from "../../components/hooks/HomePartFiveInfo/HomePartFiveInfo";
import HomePartEightSlider from "../../components/hooks/HomePartEightSlider/HomePartEightSlider";
// import TestimonialHome from "../../components/hooks/TestimonialHome/TestimonialHome";
import HomeP2Svg from "../../assets/images/Home_P2.png";
import HomeP2Dot from "../../assets/svg/Home_P2_Dot.svg";
import HomeP4Aws from "../../assets/images/Aws_P4_Partner.png";
import HomeP4Sap from "../../assets/images/Home_P4_Sap.png";
import HomeP4Microsoft from "../../assets/images/Home_P4_Microsoft.png";
import HomeP4Trend from "../../assets/images/Trend_P1_Logo_Home.png";
import HomeP4Odoo from "../../assets/images/Odoo_P1_Logo.png";
// import { ReactComponent as HomeP4Analyst } from "../../assets/svg/Home_P4_Analyst.svg";
import { ReactComponent as SpaceBetweenSec } from "../../assets/svg/careers-space.svg";
import HomeP7Img from "../../assets/images/Home_P7_Img.png";
import HomeP6S2Arrow from "../../assets/svg/Home_P6_S2_Arrow.svg";
import HomeP6S2N55 from "../../assets/svg/Home_P6_S2_55.svg";
import HomeP6S2N10 from "../../assets/svg/Home_P6_S2_10.svg";
import HomeP6S2N25 from "../../assets/svg/Home_P6_S2_25.svg";
import HomeP6S2N50 from "../../assets/svg/Home_P6_S2_50.svg";
import HomeP6S2N120 from "../../assets/svg/Home_P6_S2_120.svg";
// import videohome from "../../assets/videos/HomeP1Slider1.mp4";
import LazyMedia from "../../lazyLoad/lazyLoad";

// end of import Svg for Home Page

function HomeContent() {
  const HomeP4AccordionData = [
    {
      id: "AccTwo",
      title: "SAP",
      title2: "View partnership",
      content:
        "SAP is a leading enterprise software company known for its ERP and business software solutions, helping organizations manage core business processes. It offers a wide range of tools for finance, HR, supply chain, and customer management.",
      img: <LazyMedia type="image" src={HomeP4Sap} alt="AWS Partner" className="HomeP4Aws" />,
      urlLink: "sap-partnership",
    },
    {
      id: "AccOne",
      title: "AWS",
      title2: "View partnership",
      content:
        "Amazon Web Services (AWS) is a leading cloud computing platform that offers a wide range of scalable and reliable cloud services, including storage, compute, database, and more. It is widely used by businesses and organizations for its flexibility and infrastructure solutions.",
      img: <LazyMedia type="image" src={HomeP4Aws} alt="AWS Partner" className="HomeP4Aws" />,

      urlLink: "aws-partnership",
    },
    {
      id: "AccThree",
      title: "Microsoft Azure",
      title2: "View partnership",
      content:
        "Microsoft Azure is a comprehensive cloud computing platform with a wide range of services for businesses and developers. It facilitates application development and management through a global network of data centers.",
      img: <LazyMedia type="image" src={HomeP4Microsoft} alt="AWS Partner" className="HomeP4Aws" />,
      urlLink: "Azure-partnership",
    },
    {
      id: "AccFour",
      title: "Trend Micro",
      title2: "View partnership",
      content:
        "Trend Micro is a well-established cybersecurity company known for its innovative solutions in threat detection and defense against cyberattacks. Their products and services provide robust protection for businesses and individuals in an increasingly digital world.",
      img: <LazyMedia type="image" src={HomeP4Trend} alt="AWS Partner" className="HomeP4Aws" />,
      urlLink: "Trend-Micro",
    },
    // {
    //   id: "AccFive",
    //   title: "odoo",
    //   title2: "View partnership",
    //   content:
    //     "Odoo is an open-source business management software suite that offers a wide range of applications for various business needs, including CRM, sales, inventory, and accounting. It is designed to help companies streamline their operations and improve efficiency through an integrated and customizable platform.",
    //   img: (
    //     <LazyMedia
    //       type="image"
    //       src={HomeP4Odoo}
    //       alt="AWS Partner"
    //       className="HomeP4Aws"
    //     />
    //   ),
    //   url: "Odoo",
    // },
    // {
    //   id: "AccSix",
    //   title: "THE ANALYST",
    //   title2: "View partnership",
    //   content: `"The Analyst" represents a state-of-the-art football analytics solution, empowering decision makers putting strategic plans with data-driven analysis that bolster and catalyze teams’ performance.`,
    //   img: <HomeP4Analyst />,
    //   url: "",
    // },
  ];
  // const [activeIndex, setActiveIndex] = useState(null);

  // const handleMouseEnter = (index) => {
  //   setActiveIndex(index);
  // };

  // const handleMouseLeave = () => {
  //   setActiveIndex(null);
  // };

  // const toggleAccordion = (index) => {
  //   setActiveIndex(activeIndex === index ? null : index);
  // };
  return (
    <>
      <div className="HomeContainer">
        {/* Home Part One */}
        <div className="HomePartOne">
          <HomeSlideOne />
        </div>

        {/* end Home Part One */}
        {/* Home Part Two */}
        <div className="HomePartTwo">
          <div className="Home_P2_Main">
            {/* Home Part Two Section One */}
            <div className="Home_P2_First">
              <h1 className="Home_P2_First_H1">What defines Phoenix, as Phoenix?</h1>
              <p className="Home_P2_First_P">
                Regardless of your industry, location, or destination. We stand by your side, no matter the circumstances, to address your unique challenges
                with tailored solutions. We're driven by a relentless commitment to help you navigate the complexities of today's business landscape, ensuring
                success, step by step.
              </p>
              <div className="Home_P2_S1">
                <Link to="/About-us">
                  <button className="Home_P2_S1_button">read more about us</button>
                </Link>
                <LazyMedia type="image" src={HomeP2Dot} alt="HomeP2Dot" className="HomeP2Dot" />
              </div>
            </div>
            {/* Home Part Two Section Two */}
            <div className="Home_P2_Second">
              <LazyMedia type="image" src={HomeP2Svg} alt="HomeP2Svg" className="HomeP2Svg" />
            </div>
          </div>
        </div>

        {/* end Home Part Two */}
        {/* Home Part Three */}
        <div className="HomePartThree">
          <HomePartThreeSliders />
        </div>
        {/* end Home Part Three */}
        {/* Home Part Four */}
        <div className="HomePartFour">
          <div className="HomeP4_S1">
            <h1 className="HomeP4_S1_H1">our strategic partners for success</h1>
            <p className="HomeP4_S1_P">
              We place immense emphasis on forging strategic alliances with world-renowned technology leaders. These partnerships form the cornerstone of our
              philosophy, enabling us to harness the full potential of digital transformation initiatives undertaken by our clients. Our partner ecosystem is
              expansive, and we remain committed to exploring novel avenues for collaboration.
            </p>
          </div>
          <div className="HomeP4_S2_Container">
            {HomeP4AccordionData.map((data, index) => (
              <HomeP4AccordionItem key={index} title={data.title} title2={data.title2} content={data.content} img={data.img} urlLink={data.urlLink} />
            ))}
          </div>
        </div>
        {/* end Home Part Four */}
        {/* Home Space */}
        <div className="HomeSpace">
          <SpaceBetweenSec />
        </div>
        {/* Home Part Five */}
        <div className="HomePartFive">
          <div className="Home_P5_FirstSection">
            <h1 className="Home_P5_H1">Our Core Values</h1>
          </div>
          <HomePartFiveInfo />
        </div>
        {/* Home Space */}
        <div className="HomeSpace">
          <SpaceBetweenSec />
        </div>
        {/* Home Part Six */}
        <div className="HomePartSix">
          <div className="Home_P6_S1">
            <h1 className="Home_P6_S1_H1">Let numbers speak for us</h1>
          </div>
          <div className="Home_P6_S2">
            {/* element one */}
            <div className="Home_P6_S2_Element">
              <LazyMedia type="image" src={HomeP6S2N55} alt="Number" className="HomeP6S2Img" />
              <h3 className="HomeP6S2H3">Projects</h3>
            </div>
            <div className="Home_P6_S2_Arrow">
              <LazyMedia type="image" src={HomeP6S2Arrow} alt="ArrowSpace" />
            </div>
            {/* element two */}
            <div className="Home_P6_S2_Element">
              <LazyMedia type="image" src={HomeP6S2N10} alt="Number" className="HomeP6S2Img" />
              <h3 className="HomeP6S2H3">years of experience</h3>
            </div>
            <div className="Home_P6_S2_Arrow">
              <LazyMedia type="image" src={HomeP6S2Arrow} alt="ArrowSpace" className="Home_P6_S2_ArrowHide" />
            </div>
            {/* element three */}
            <div className="Home_P6_S2_Element">
              <LazyMedia type="image" src={HomeP6S2N25} alt="Number" className="HomeP6S2Img" />
              <h3 className="HomeP6S2H3">Support contract</h3>
            </div>
            <div className="Home_P6_S2_Arrow">
              <LazyMedia type="image" src={HomeP6S2Arrow} alt="ArrowSpace" className="HomeP6S2ArrowShow" />
            </div>
            {/* element four */}
            <div className="Home_P6_S2_Element">
              <LazyMedia type="image" src={HomeP6S2N50} alt="Number" className="HomeP6S2Img" />
              <h3 className="HomeP6S2H3">Customer base</h3>
            </div>
            <div className="Home_P6_S2_Arrow">
              <LazyMedia type="image" src={HomeP6S2Arrow} alt="ArrowSpace" className="Home_P6_S2_ArrowHide" />
            </div>
            {/* element five */}
            <div className="Home_P6_S2_Element">
              <LazyMedia type="image" src={HomeP6S2N120} alt="Number" className="HomeP6S2Img120" />
              <h3 className="HomeP6S2H3">workforce</h3>
            </div>
          </div>
        </div>
        {/* Home Space */}
        <div className="HomeSpace">
          <SpaceBetweenSec />
        </div>
        {/* Home Part Seven */}
        <div className="HomePartSeven">
          <div className="Home_P7_S1">
            <h1 className="Home_P7_S1_H1">Shaping a Future of Digital Success</h1>
            <p className="Home_P7_S1_P">
              Discover how we're shaping a future where businesses thrive in the digital age. Be part of our mission to empower organizations with
              transformative technology solutions
            </p>
            <Link to="/Contact-us">
              <button className="Home_P7_S1_Button">Join now</button>
            </Link>
          </div>
          <div className="Home_P7_S2">
            <LazyMedia type="image" src={HomeP7Img} alt="HomeP7Img" className="HomeP7Img" />
          </div>
        </div>
        {/* Home Part Eight */}
        <div className="HomePartEight">
          <div className="Home_P8_S1">
            <h1 className="Home_P8_S1_H1">We build solutions for</h1>
          </div>
          <div className="Home_P8_S2">
            <HomePartEightSlider />
          </div>
        </div>
        {/* Home Part Nine */}
        {/* <div className="HomePartNine">
          <TestimonialHome />
        </div> */}
      </div>
    </>
  );
}

export default HomeContent;
