import React from "react";
import "./footerMobile.css";
import { ReactComponent as Inbox } from "../../assets/svg/inbox.svg";
import { ReactComponent as DotMenu } from "../../assets/svg/dot-menu.svg";
import { ReactComponent as BackgroundSvg } from "../../assets/svg/bg-footer.svg";
import { ReactComponent as GoOut } from "../../assets/svg/go-out.svg";
import { ReactComponent as Facebook } from "../../assets/svg/facebook.svg";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcons from "../../assets/svg/YoutubeIcon.png";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Link } from "react-router-dom";

function FooterMobile() {
  return (
    <>
      <div className="footerMobile">
        {/* footer part 1 */}
        {/* end footer part 1 */}
        <div className="footerMobile_part_s">
          <div className="FooterMobile_part_s1">We look forward to hearing from you</div>
          <div className="FooterMobile_part_s2">
            <Link to="/contact-us">
              <button className="FooterMobile_part_sButton">
                <MailOutlineIcon className="FooterMobile_part_sIcon" />
                Get in Touch
              </button>
            </Link>
          </div>
        </div>
        {/* start footer part 2  */}
        <div className="footerMobile_part2">
          <BackgroundSvg className="backgroundFooterMobile_svg" />
          <div className="FooterMobile_Part2_part1">
            <div className="FooterMobile_part2_first_link">
              <DotMenu className="footerMobile_icon" />
              <span className="FooterMobile_Main-link">Company</span>
            </div>
            <div className="FooterMobile_LinkContainer">
              <Link to="/about-us">About Us</Link>
              <Link to="/Careers">Careers</Link>
            </div>
          </div>
          {/*  */}
          {/*  */}
          <div className="FooterMobile_Part2_part2">
            <div className="FooterMobile_part2_first_link">
              <DotMenu className="footerMobile_icon" />
              <span className="FooterMobile_Main-link">Help</span>
            </div>
            <div className="FooterMobile_LinkContainer">
              {/* <a href={`${MainUrl}`}>Terms & Conditions</a> */}
              <Link to="/Contact-us">Contact Us</Link>
              <Link to="/Clients">Clients</Link>
            </div>
          </div>
          {/*  */}
          {/*  */}
          <div className="FooterMobile_part3">
            <div className="FooterMobile_part2_first_link">
              <DotMenu className="footerMobile_icon" />
              <span className="FooterMobile_Main-link">Branches location</span>
            </div>

            <div className="FooterMobile_AddContainer">
              <h4 className="footer_part3_h4_Mobile">Phoenix Consulting</h4>
              <p className="FooterMobile_Add_P">
                Egypt: Al Jazeera 1 Tower, beside Sadat Academy <p className="FooterMobile_Add_M">Maadi - Cairo</p>
              </p>
              <h4 className="footer_part3_h4_Mobile">Phoenix IT Solutions</h4>

              <p className="FooterMobile_Add_P">Dubai: Office NO.04- 46, King Saeed Suhail Saeed bin Deliwi Al Ketbi - Ras Al Khor Second Industrial, Dubai</p>
              <h4 className="footer_part3_h4_Mobile">Phoenix Tech Solutions</h4>

              <p className="FooterMobile_Add_P">USA: Office 2248 BROADWAY NEWYORK, NY , USA</p>
            </div>
          </div>
          {/*  */}
          {/*  */}
          <div className="FooterMobile_part4">
            <div className="FooterMobile_part2_first_link">
              <DotMenu className="footerMobile_icon" />
              <span className="FooterMobile_Main-link">Contacts</span>
            </div>
            <div className="FooterMobile_ContactContainer">
              <p className="FooterMobile_Con_P">Egypt Office: +201016165700</p>
              <p className="FooterMobile_Con_P">Dubai Office: +971 527673263</p>
              <p className="FooterMobile_Con_P">USA Office: (+1)201-449-4229</p>
              <a href="mailto:info@phoenix-mea.com" className="FooterMobile_Con_P">
                info@phoenix-mea.com
              </a>
            </div>
          </div>
          {/*  */}
          <div className="FooterMobile_Part5">
            <div className="FooterMobile_Part5_First">
              {/* linked in */}
              <a href="https://www.linkedin.com/company/phoenix-consulting-egy/" target="blank">
                <div className="footer_social">
                  <LinkedInIcon className="social_icon" />
                </div>
              </a>
              {/* facebook */}
              <a href="https://www.facebook.com/phoenixconsultingmea" target="blank">
                <div className="footer_social">
                  <Facebook className="social_icon" />
                </div>
              </a>
              {/* insta */}
              <a href="https://www.instagram.com/phoenixconsultingmea/?igshid=MzRlODBiNWFlZA%3D%3D" target="blank">
                <div className="footer_social">
                  <InstagramIcon className="social_icon" />
                </div>
              </a>
              {/* youtube */}
              <a href="https://www.youtube.com/@phoenixconsultingmea" target="blank">
                <div className="footer_social">
                  <img src={YouTubeIcons} alt="" className="social_icon_youtube" />
                  {/*  */}
                </div>
              </a>
            </div>
          </div>
          {/*  */}
        </div>
        {/* end footer part 2 */}
        {/* start footer part 3 */}
        {/* end footer part 3 */}
      </div>
    </>
  );
}

export default FooterMobile;
